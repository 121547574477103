// extracted by mini-css-extract-plugin
export var header = "Header-module--header--1Qt4T";
export var headerAlternateLink = "Header-module--headerAlternateLink--26Cqq";
export var headerCtaMessageWrapper = "Header-module--headerCtaMessageWrapper--3Uccq";
export var headerCtaMessageLink = "Header-module--headerCtaMessageLink--2RTAQ";
export var headerWrapper = "Header-module--headerWrapper--1oGfD";
export var headerImage = "Header-module--headerImage--Mh3IR";
export var headerNav = "Header-module--headerNav--1zFY-";
export var headerNavItems = "Header-module--headerNavItems--3-php";
export var headerNavLinkActive = "Header-module--headerNavLinkActive--OgTzU";
export var headerNavLinkText = "Header-module--headerNavLinkText--3XiBB";
export var headerNavLink = "Header-module--headerNavLink--1hCMs";
export var headerSecondaryNav = "Header-module--headerSecondaryNav--1FCnE";
export var warning = "Header-module--warning--176Lr";
export var alertsNotification = "Header-module--alertsNotification--1nV13";
export var headerBtn = "Header-module--headerBtn--1511d";
export var headerBtnInfoWrapper = "Header-module--headerBtnInfoWrapper--3XFZg";
export var headerBtnInfo = "Header-module--headerBtnInfo--1Tmn_";
export var headerBtnNotification = "Header-module--headerBtnNotification--h-9Kn";
export var headerNavLinkIcon = "Header-module--headerNavLinkIcon--2bEsJ";
export var tooltip = "Header-module--tooltip--2MPDn";
export var widget = "Header-module--widget--3yOAn";
export var languageSwitcher = "Header-module--languageSwitcher--ny_zu";
export var languageSwitcherAltLanguage = "Header-module--languageSwitcherAltLanguage--1YwHH";
export var activeLink = "Header-module--activeLink--17Pq4";
export var languageSwitcherCurrentLanguage = "Header-module--languageSwitcherCurrentLanguage--3KQ59";
export var affiliate = "Header-module--affiliate--e2F-K";
export var affiliate__text = "Header-module--affiliate__text--zk8sg";
export var affiliate__button = "Header-module--affiliate__button--2UsaT";