import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Icon, iconsList } from "../../Icons";
import { Card } from "../Card";
import { Link } from "../../../sharedComponents/Link";
import { HeadingAsElement, HeadingSize } from "../../Heading";

import * as styles from "./InfoCard.module.scss";

const InfoCard = ({
	className,
	icon,
	header,
	headerAs,
	headerSize,
	subheading,
	variant,
	content,
	link: button,
	LinkComponent,
}) => (
	<div className={cx(styles.infoCard, styles[variant], className)}>
		{icon && <Icon name={icon} className={cx(styles.infoIcon)} />}
		{header && (
			<Card.Header as={headerAs} size={headerSize} align="center" className={styles.infoHeading}>
				{header}
			</Card.Header>
		)}
		{subheading && <div className={styles.horizontalSubheading}>{subheading}</div>}
		{content && <div className={cx(styles.infoContent)}>{content}</div>}
		{button &&
			(variant === "horizontal" ? (
				<Link to={button.to} target={button.target} className={styles.horizontalBtn}>
					{button.text}
				</Link>
			) : (
				<div>
					<Card.Button
						className={cx(styles.infoButton)}
						icon={button.icon}
						iconPlacement={button.iconPlacement}
						label={button.text}
						theme={button.theme}
					>
						{button.text}
					</Card.Button>
					<Card.AsLink
						LinkComponent={LinkComponent}
						link={button.to}
						target={button.target}
						text={button.text}
						className={styles?.infoCardLink}
					/>
				</div>
			))}
	</div>
);

InfoCard.propTypes = {
	className: PropTypes.string,
	header: PropTypes.string,
	headerAs: PropTypes.oneOf(Object.keys(HeadingAsElement)),
	headerSize: PropTypes.oneOf(Object.keys(HeadingSize)),
	content: PropTypes.string,
	subheading: PropTypes.string,
	icon: PropTypes.string,
	link: PropTypes.shape({
		to: PropTypes.string,
		target: PropTypes.string,
		icon: PropTypes.oneOf(iconsList),
		iconPlacement: PropTypes.oneOf(["right", "left"]),
		className: PropTypes.string,
		text: PropTypes.string,
		theme: PropTypes.oneOf(["primary", "secondary", "tertiary", "quaternary"]),
	}),
	variant: PropTypes.oneOf(["info", "horizontal", "button"]),
	LinkComponent: PropTypes.func,
};

InfoCard.defaultProps = {
	header: undefined,
	headerAs: "h3",
	headerSize: "h3",
	className: "",
	content: null,
	subheading: null,
	link: undefined,
	icon: undefined,
	variant: "info",
	LinkComponent: undefined,
};

export default InfoCard;
export { InfoCard };
