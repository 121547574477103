import React, { useEffect, useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { throttle } from "throttle-debounce";
// eslint-disable-next-line import/no-cycle
import { Button } from "../Button";

import * as styles from "./ResponsiveBanner.module.scss";

const ResponsiveBanner = ({
	url,
	description,
	alignment,
	button,
	target,
	banners,
	margins,
	useDynamicButton,
}) => {
	// Render source set HTML
	const [sourceSets, setSourceSets] = useState();
	const bannerHeightArray = useRef([]);
	const [buttonSize, setButtonSize] = useState(button?.size || "md");
	const breakpointMD = 991;
	const breakpointXS = 767;

	// Render image HTML attributes
	const imgSrcSet = useRef([]);
	const [imgSrc, setImgSrc] = useState("");

	const [showButton, setShowButton] = useState(false);
	const [showAnchor, setShowAnchor] = useState(false);

	const generateSourceSet = () => {
		const bannersTotal = banners.length;

		const sourceSet = banners.map((banner, bIndex) => {
			const { viewportWidth, imageWidth, aspectRatio, cloudinaryAsset } = banner;
			const { secure_url, raw_transformation } = cloudinaryAsset[0];

			const minMax = bannersTotal - 1 === bIndex ? "max" : "min";
			const media = `(${minMax}-width: ${viewportWidth}px)`;
			const sizes = `(${minMax}-width: ${viewportWidth}px) 100vw ${imageWidth}px`;

			const srcSetDomain = `https://res.cloudinary.com/${process.env.GATSBY_CLOUDINARY_CLOUD_NAME}/`;

			const ratioArray = aspectRatio.split(":");
			const ratioN = parseFloat(ratioArray[0], 10);
			const ratioD = parseFloat(ratioArray[1], 10);
			const ratio = (ratioN / ratioD).toFixed(5);

			const height = Math.floor(imageWidth / ratio);
			const srcSetTransformation = `c_fill,ar_${ratio},q_auto,f_auto,w_${imageWidth},h_${height}`;

			const imagePathArray = secure_url.split(raw_transformation);
			const imagePath = `${srcSetDomain}${srcSetTransformation}${imagePathArray[1]}`;
			const srcSet = `${imagePath} ${viewportWidth}w`;

			if (bIndex === 0) {
				setImgSrc(imagePath);
			}

			imgSrcSet.current.push(srcSet);
			bannerHeightArray.current.push({
				media,
				height: `${(100 / ratio).toFixed(3)}vw`,
			});

			return <source media={media} sizes={sizes} srcSet={srcSet} />;
		});

		return sourceSet;
	};

	const generateInitHeight = () => {
		const { aspectRatio } = banners[banners.length - 1];

		const ratioArray = aspectRatio.split(":");
		const ratioN = parseFloat(ratioArray[0], 10);
		const ratioD = parseFloat(ratioArray[1], 10);
		const ratio = (ratioN / ratioD).toFixed(5);

		return `${(100 / ratio).toFixed(3)}vw`;
	};

	const [bannerHeight, setBannerHeight] = useState(banners?.length ? generateInitHeight() : "auto");

	const handleResize = throttle(
		200,
		useCallback(() => {
			let isMatch = false;

			bannerHeightArray.current.forEach(bannerItem => {
				if (
					matchMedia(bannerItem.media).matches &&
					!isMatch &&
					bannerHeightArray.current.length > 1
				) {
					if (bannerHeight !== bannerItem.height) {
						setBannerHeight(bannerItem.height);
					}

					isMatch = true;
				} else if (bannerHeightArray.current.length === 1) {
					// Case where 1 image was provided
					setBannerHeight(bannerItem.height);
				}
			});

			if (useDynamicButton) {
				if (window.innerWidth <= breakpointXS) {
					setButtonSize("xs");
				} else if (window.innerWidth <= breakpointMD) {
					setButtonSize("md");
				} else {
					setButtonSize("lg");
				}
			}
		}, [bannerHeight, useDynamicButton])
	);

	useEffect(() => {
		if (button?.linkText && button?.url && button?.target) {
			setShowButton(true);
		}

		if (url && target) {
			setShowAnchor(true);
		}

		// Init component settings
		setSourceSets(generateSourceSet());

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		window.addEventListener("resize", handleResize, false);

		handleResize();

		return () => {
			window.removeEventListener("resize", handleResize);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bannerHeight]);

	return (
		<div
			className={cx(
				styles.banner,
				showButton && styles.showButton,
				alignment && styles[`${alignment}Align`],
				margins && margins?.[0] && styles[`${margins[0]}Margin`],
				margins && margins?.[1] && styles[`${margins[1]}Margin`]
			)}
			style={{ height: bannerHeight }}
		>
			{showButton && (
				<div className={styles.buttonWrapper}>
					<div className={styles.buttonContainer}>
						<Button
							theme={button?.variant || "primary"}
							to={button.url}
							target={button.target || "_self"}
							size={buttonSize}
						>
							{button.linkText}
						</Button>
					</div>
				</div>
			)}
			{showAnchor && description && (
				<a
					className={styles.anchorLink}
					href={button?.url || url}
					target={button?.target || target}
				>
					<span className="sr-only">{button?.linkText || description}</span>
				</a>
			)}
			{imgSrc && sourceSets && (
				<div className={styles.background}>
					<picture>
						{sourceSets}
						<img src={imgSrc} alt={description} />
					</picture>
				</div>
			)}
		</div>
	);
};

ResponsiveBanner.propTypes = {
	url: PropTypes.string,
	description: PropTypes.string,
	alignment: PropTypes.string,
	target: PropTypes.string,
	button: PropTypes.shape({
		linkText: PropTypes.string,
		url: PropTypes.string,
		target: PropTypes.oneOf(["_self", "_blank", "_top", "_parent"]),
		variant: PropTypes.oneOf(["primary", "secondary"]),
		size: PropTypes.oneOf(["sm", "md", "lg", "auto"]),
	}),
	margins: PropTypes.arrayOf(PropTypes.string),
	useDynamicButton: PropTypes.bool,
	banners: PropTypes.arrayOf([
		PropTypes.shape({
			aspectRatio: PropTypes.string,
			imageWidth: PropTypes.number,
			viewportWidth: PropTypes.number,
			cloudinaryAsset: PropTypes.arrayOf([
				PropTypes.shape({
					format: PropTypes.string,
					height: PropTypes.number,
					width: PropTypes.number,
					version: PropTypes.number,
					public_id: PropTypes.string,
					raw_transformation: PropTypes.string,
					secure_url: PropTypes.string,
					url: PropTypes.string,
				}),
			]),
		}),
	]),
};

ResponsiveBanner.defaultProps = {
	url: undefined,
	description: "",
	alignment: "left",
	target: "_self",
	button: undefined,
	banners: undefined,
	margins: undefined,
	useDynamicButton: false,
};

export default ResponsiveBanner;
export { ResponsiveBanner };
