import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { PopoverInput, StepperInput, Link } from "@sunwing/shared-components";
import { RCL as useTranslation } from "../../../../RCL";

import { SelectInput } from "./SelectInput";
import { Button } from "../../../../Button";
import { validateRoomOccupancies } from "./utils/validate-room-occupancies";

import * as styles from "./RoomsAndGuestsField.module.scss";
import * as stepperStyles from "./StepperInput.module.scss";

const RoomsGuestsField = ({
	id,
	classNames,
	selectedRoomsOccupancies,
	maxChildAge,
	onChange,
	groupsURL,
	roomsMax,
	adultsMax,
	childrenMax,
	occupancyMax,
	searchType,
}) => {
	const dictionary = {
		"rooms-and-guests": useTranslation({ searchKey: "rooms-and-guests" }),
		"decrease-value": useTranslation({ searchKey: "decrease-value" }),
		"increase-value": useTranslation({ searchKey: "increase-value" }),
		"rooms-guests-validation-room-adults": useTranslation({
			searchKey: "rooms-guests-validation-room-adults",
		}),
		"rooms-guests-validation-room-children": useTranslation({
			searchKey: "rooms-guests-validation-room-children",
		}),
		"rooms-guests-validation-children-age": useTranslation({
			searchKey: "rooms-guests-validation-children-age",
		}),
		"age-upon-return-for-each-child": useTranslation({
			searchKey: "age-upon-return-for-each-child",
		}),
		rooms: useTranslation({ searchKey: "rooms" }),
		room: useTranslation({ searchKey: "room" }),
		adults: useTranslation({ searchKey: "adults" }),
		adult: useTranslation({ searchKey: "adult" }),
		children: useTranslation({ searchKey: "children" }),
		child: useTranslation({ searchKey: "child" }),
		infants: useTranslation({ searchKey: "infants" }),
		infant: useTranslation({ searchKey: "infant" }),
		"18-yrs": useTranslation({ searchKey: "18-yrs" }),
		"12-yrs": useTranslation({ searchKey: "12-yrs" }),
		"2-17-yrs": useTranslation({ searchKey: "2-17-yrs" }),
		"2-11-yrs": useTranslation({ searchKey: "2-11-yrs" }),
		"0-11-yrs": useTranslation({ searchKey: "0-11-yrs" }),
		"childs-age-upon-return": useTranslation({ searchKey: "childs-age-upon-return" }),
		"children-age": useTranslation({ searchKey: "children-age" }),
		"infant-s": useTranslation({ searchKey: "infant-s" }),
		"0-23-mo": useTranslation({ searchKey: "0-23-mo" }),
		"10-travellers": useTranslation({ searchKey: "10-travellers" }),
		done: useTranslation({ searchKey: "done" }),
		"number-of-people": useTranslation({ searchKey: "number-of-people" }),
	};

	const VALIDATIONS = {
		MAX_OCCUPANCIES: occupancyMax || 10,
		MIN_ROOMS: 1,
		MAX_ROOMS: roomsMax || 10,
		MIN_ADULTS: 1,
		MAX_ADULTS: adultsMax || 10,
		// Includes infants
		MIN_CHILDREN: 0,
		MAX_CHILDREN: childrenMax || 9,
	};

	// IE 11 Fix - State is stale when passed used by validateSelection
	const latestRoomsOccupancies = useRef(selectedRoomsOccupancies);
	const [maxAdultsStepperValue, setMaxAdultsStepperValue] = useState(VALIDATIONS.MAX_ADULTS);
	const [maxChildrenStepperValue, setMaxChildrenStepperValue] = useState(VALIDATIONS.MAX_CHILDREN);
	const [maxInfantsStepperValue, setMaxInfantsStepperValue] = useState(VALIDATIONS.MAX_CHILDREN);
	const [isValidRoomCombination, setIsValidRoomCombination] = useState(true);
	const [isValidAdultsCombination, setIsValidAdultsCombination] = useState(true);
	const [isValidChildrenCombination, setIsValidChildrenCombination] = useState(true);
	const [isValidInfantsCombination, setIsValidInfantsCombination] = useState(true);
	const [isValidAgeSelection, setIsValidAgeSelection] = useState([]);

	const [displayRoomAdultCombinationError, setDisplayRoomAdultCombinationError] = useState(false);
	const [displayRoomChildrenCombinationError, setDisplayRoomChildrenCombinationError] =
		useState(false);
	const [displayAgeError, setDisplayAgeError] = useState(false);

	const [disableRoomsInput, setDisableRoomsInput] = useState(false);
	const [disableChildrenInput, setDisableChildrenInput] = useState(false);

	const stepperLabels = {
		decreaseValue: dictionary["decrease-value"],
		increaseValue: dictionary["increase-value"],
	};

	const convertToDisplayValue = occupanciesData => {
		let displayValue = "";

		if (VALIDATIONS.MAX_ROOMS > 1) {
			// Rooms
			displayValue += `${occupanciesData?.rooms} ${
				occupanciesData?.rooms < 2 ? dictionary.room : dictionary.rooms
			},`;
		}

		// Adults
		displayValue += ` ${occupanciesData?.adults} ${
			occupanciesData?.adults < 2 ? dictionary.adult : dictionary.adults
		}`;

		// Children
		if (occupanciesData?.children?.length) {
			displayValue += `, ${occupanciesData?.children?.length} ${
				occupanciesData?.children?.length < 2 ? dictionary.child : dictionary.children
			}`;
		}

		// Infants
		if (occupanciesData?.infants) {
			displayValue += `, ${occupanciesData?.infants} ${
				occupanciesData?.infants < 2 ? dictionary.infant : dictionary.infants
			}`;
		}

		return displayValue;
	};

	const childAgeOptions = Array.from(Array(maxChildAge).keys()).map(number => {
		if (number === 0) {
			if (searchType === "Flights") {
				return {
					label: 1,
					value: 1,
				};
			}
			return {
				label: "--",
				value: "--",
			};
		}
		return {
			label: number + 1,
			value: number + 1,
		};
	});

	if (searchType === "Flights") {
		childAgeOptions.unshift({ label: "< 1", value: 0 });
		childAgeOptions.unshift({ label: "--", value: "--" });
	}

	const resetValidation = () => {
		setIsValidRoomCombination(true);
		setIsValidAdultsCombination(true);
		setIsValidChildrenCombination(true);
		setIsValidInfantsCombination(true);
		setIsValidAgeSelection([]);
		setDisplayRoomAdultCombinationError(false);
		setDisplayRoomChildrenCombinationError(false);
		setDisplayAgeError(false);
	};

	const updateConstraintsAndValidation = ({ rooms, adults, children, infants }) => {
		resetValidation();

		setDisableChildrenInput(rooms > 1);

		setMaxAdultsStepperValue(VALIDATIONS.MAX_ADULTS);

		setMaxChildrenStepperValue(VALIDATIONS.MAX_CHILDREN - infants);
		setMaxInfantsStepperValue(VALIDATIONS.MAX_CHILDREN - children.length);

		setDisableRoomsInput(children.length + infants > 0);

		setDisplayRoomAdultCombinationError(adults % rooms !== 0);
	};

	const handleRoomsChange = value => {
		const updatedSelectedRoomOccupancies = {
			...selectedRoomsOccupancies,
			rooms: value,
		};

		updateConstraintsAndValidation(updatedSelectedRoomOccupancies);

		onChange(updatedSelectedRoomOccupancies);
	};

	const handleAdultsChange = value => {
		const updatedSelectedRoomOccupancies = {
			...selectedRoomsOccupancies,
			adults: value,
		};

		updateConstraintsAndValidation(updatedSelectedRoomOccupancies);

		onChange(updatedSelectedRoomOccupancies);
	};
	const handleChildrenChange = value => {
		let newChildren = [...selectedRoomsOccupancies.children];

		if (newChildren.length > value) {
			newChildren = newChildren.splice(0, value);
		} else {
			newChildren = newChildren.concat(new Array(value - newChildren.length).fill("--"));
		}

		const updatedSelectedRoomOccupancies = {
			...selectedRoomsOccupancies,
			children: newChildren,
		};

		updateConstraintsAndValidation(updatedSelectedRoomOccupancies);

		onChange(updatedSelectedRoomOccupancies);
	};

	const handleInfantsChange = value => {
		const updatedSelectedRoomOccupancies = {
			...selectedRoomsOccupancies,
			infants: value,
		};

		updateConstraintsAndValidation(updatedSelectedRoomOccupancies);

		onChange(updatedSelectedRoomOccupancies);
	};

	const validateSelection = () => {
		const validation = validateRoomOccupancies(latestRoomsOccupancies.current);

		setIsValidRoomCombination(validation.isValidRoomCombination);
		setIsValidAdultsCombination(validation.isValidAdultsCombination);
		setIsValidChildrenCombination(validation.isValidChildrenCombination);
		setIsValidInfantsCombination(validation.isValidInfantsCombination);
		setDisplayRoomAdultCombinationError(!validation.isValidAdultsCombination);
		setDisplayRoomChildrenCombinationError(!validation.isValidChildrenCombination);
		setIsValidAgeSelection(validation.isValidChildAges);
		setDisplayAgeError(!validation.isValidChildAges.every(validAge => validAge));

		return validation.isValid;
	};

	useEffect(() => {
		latestRoomsOccupancies.current = selectedRoomsOccupancies;
		updateConstraintsAndValidation(selectedRoomsOccupancies);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedRoomsOccupancies]);

	return (
		<PopoverInput
			label={
				VALIDATIONS.MAX_ROOMS > 1 ? dictionary["rooms-and-guests"] : dictionary["number-of-people"]
			}
			id={id}
			className={cx(styles.roomsAndGuestsPopover)}
			classNames={{
				label: cx(styles.label, classNames?.label),
				popoverWrapper: cx(styles.popoverWrapper, classNames?.popoverWrapper),
				popoverOpen: cx(styles.popoverOpen, classNames?.popoverOpen),
				focused: cx(styles.focused, classNames?.focused),
				input: cx(styles.input, classNames?.input),
				inputWithIcon: cx(styles.inputWithIcon, classNames?.inputWithIcon),
				icon: cx(styles.icon, classNames?.icon),
				popover: cx(styles.popover, classNames?.popover),
				open: cx(classNames?.open),
				closeButton: cx(styles.closeButton, classNames?.closeButton),
			}}
			icon={
				<span focus-trap-toggle="true">
					<span className="sr-only">{dictionary["rooms-and-guests"]}</span>
				</span>
			}
			value={convertToDisplayValue(selectedRoomsOccupancies)}
			onChange={onChange}
			onClose={() => validateSelection(selectedRoomsOccupancies)}
			defaultCloseIcon={false}
		>
			{({ handleClose }) => (
				<fieldset className={cx(styles.containerRGF)}>
					{VALIDATIONS.MAX_ROOMS > 1 && (
						<div
							className={styles.roomCombinationDescription}
							id="packages-rooms-guests-combination-description"
						>
							<span
								className={cx(
									displayRoomAdultCombinationError && styles.roomCombinationDescriptionError
								)}
								aria-live={displayRoomAdultCombinationError ? "polite" : "off"}
							>
								{dictionary["rooms-guests-validation-room-adults"]}
							</span>
							<span
								className={cx(
									displayRoomChildrenCombinationError && styles.roomCombinationDescriptionError
								)}
								aria-live={displayRoomChildrenCombinationError ? "polite" : "off"}
							>
								{dictionary["rooms-guests-validation-room-children"]}
							</span>
						</div>
					)}
					{VALIDATIONS.MAX_ROOMS > 1 && (
						<StepperInput
							id="rooms"
							className={stepperStyles.stepper}
							classNames={{
								label: stepperStyles.label,
								stepper: stepperStyles.stepperWrapper,
								button: stepperStyles.button,
								input: stepperStyles.input,
								ariaLive: stepperStyles.ariaLive,
							}}
							label={dictionary.rooms}
							labels={stepperLabels}
							value={selectedRoomsOccupancies.rooms}
							disabled={disableRoomsInput}
							error={!isValidRoomCombination}
							ariaDescribedby="packages-rooms-guests-combination-description"
							minValue={VALIDATIONS.MIN_ROOMS}
							maxValue={VALIDATIONS.MAX_ROOMS}
							onChange={handleRoomsChange}
						/>
					)}
					<StepperInput
						id="adults"
						className={stepperStyles.stepper}
						classNames={{
							label: stepperStyles.label,
							stepper: stepperStyles.stepperWrapper,
							button: stepperStyles.button,
							input: stepperStyles.input,
							ariaLive: stepperStyles.ariaLive,
							helperText: stepperStyles.helperText,
						}}
						label={dictionary.adults}
						labels={stepperLabels}
						helperText={searchType === "Flights" ? "" : dictionary["18-yrs"]}
						customDescriptionClass={stepperStyles.description}
						value={selectedRoomsOccupancies.adults}
						error={!isValidAdultsCombination}
						ariaDescribedby="packages-rooms-guests-combination-error"
						minValue={VALIDATIONS.MIN_ADULTS}
						maxValue={maxAdultsStepperValue}
						onChange={handleAdultsChange}
					/>
					<StepperInput
						id="children"
						className={stepperStyles.stepper}
						classNames={{
							label: stepperStyles.label,
							stepper: stepperStyles.stepperWrapper,
							button: stepperStyles.button,
							input: stepperStyles.input,
							ariaLive: stepperStyles.ariaLive,
							helperText: stepperStyles.helperText,
						}}
						label={dictionary.children}
						labels={stepperLabels}
						helperText={searchType === "Flights" ? dictionary["0-11-yrs"] : dictionary["2-17-yrs"]}
						customDescriptionClass={stepperStyles.description}
						value={selectedRoomsOccupancies.children.length}
						disabled={disableChildrenInput}
						error={!isValidChildrenCombination}
						ariaDescribedby="packages-rooms-guests-combination-error"
						minValue={VALIDATIONS.MIN_CHILDREN}
						maxValue={maxChildrenStepperValue}
						onChange={handleChildrenChange}
					/>
					{!!selectedRoomsOccupancies.children.length && (
						<fieldset id="childrenAge" className={stepperStyles.childAgesContainer}>
							<legend>{dictionary["children-age"]}</legend>
							<span
								className={stepperStyles.ageError}
								id="packages-rooms-guests-age-error"
								aria-live={displayAgeError ? "polite" : "off"}
							>
								{!isValidAgeSelection.every(validAge => validAge) &&
									dictionary["rooms-guests-validation-children-age"]}
							</span>
							<div
								className={cx(
									stepperStyles.childAges,
									selectedRoomsOccupancies.children.length < 3 && classNames?.childAges
								)}
							>
								{selectedRoomsOccupancies.children.map((age, index) => (
									<SelectInput
										// eslint-disable-next-line react/no-array-index-key
										key={`age-${index}`}
										id={`age-${index}`}
										className={cx(
											stepperStyles.ageSelector,
											selectedRoomsOccupancies.children.length === 2 && classNames?.AgeSelectSecond
										)}
										customSelectClass={classNames?.AgeSelect}
										label={`${dictionary.child} ${index + 1}`}
										displayInline={true}
										value={selectedRoomsOccupancies.children[index] ?? true}
										error={!(isValidAgeSelection[index] ?? true)}
										ariaDescribedby="packages-rooms-guests-age-error"
										options={childAgeOptions}
										onChange={value => {
											const newChildren = [...selectedRoomsOccupancies.children];
											newChildren[index] = value;
											resetValidation();
											onChange({
												...selectedRoomsOccupancies,
												children: newChildren,
											});
										}}
									/>
								))}
							</div>
							<span className={stepperStyles.description}>
								{searchType === "Flights"
									? dictionary["childs-age-upon-return"]
									: dictionary["age-upon-return-for-each-child"]}
							</span>
						</fieldset>
					)}
					{searchType !== "Flights" && (
						<StepperInput
							id="infants"
							className={stepperStyles.stepper}
							classNames={{
								label: stepperStyles.stepperLabel,
								stepper: stepperStyles.stepperWrapper,
								button: stepperStyles.button,
								input: stepperStyles.input,
								ariaLive: stepperStyles.ariaLive,
								helperText: stepperStyles.helperText,
							}}
							label={dictionary["infant-s"]}
							labels={stepperLabels}
							helperText={dictionary["0-23-mo"]}
							customDescriptionClass={stepperStyles.description}
							value={selectedRoomsOccupancies.infants}
							error={!isValidInfantsCombination}
							disabled={disableChildrenInput}
							ariaDescribedby="packages-rooms-guests-combination-error"
							minValue={VALIDATIONS.MIN_CHILDREN}
							maxValue={maxInfantsStepperValue}
							onChange={handleInfantsChange}
						/>
					)}
					<div className={styles.footer}>
						<div className={styles.footerURL}>
							{groupsURL && (
								<Link target="_self" to={groupsURL} className={styles.footerLink}>
									{dictionary["10-travellers"]}
								</Link>
							)}
						</div>

						<Button
							type="button"
							label={dictionary.done}
							onClick={handleClose}
							className={styles.saveButton}
						/>
					</div>
				</fieldset>
			)}
		</PopoverInput>
	);
};

RoomsGuestsField.propTypes = {
	id: PropTypes.string.isRequired,
	classNames: PropTypes.string,
	selectedRoomsOccupancies: PropTypes.shape({
		rooms: PropTypes.number,
		adults: PropTypes.number,
		children: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
		infants: PropTypes.number,
	}).isRequired,
	maxChildAge: PropTypes.number,
	onChange: PropTypes.func.isRequired,
	groupsURL: PropTypes.string,
	roomsMax: PropTypes.number,
	adultsMax: PropTypes.number,
	childrenMax: PropTypes.number,
	occupancyMax: PropTypes.number,
	searchType: PropTypes.oneOfType(["Flights", "Hotels", "Packages"]),
};

RoomsGuestsField.defaultProps = {
	classNames: undefined,
	maxChildAge: 17,
	groupsURL: undefined,
	roomsMax: undefined,
	adultsMax: undefined,
	childrenMax: undefined,
	occupancyMax: undefined,
	searchType: "Packages",
};

export default RoomsGuestsField;
export { RoomsGuestsField };
