/* eslint-disable import/no-cycle */
import React from "react";
import PropTypes from "prop-types";

import { CardConnector } from "../../connectors/CardConnector";
import { CollectionConnector } from "../../connectors/CollectionConnector";
import { ComponentBlock } from "../ComponentBlock";
import { ContactConnector } from "../../connectors/ContactConnector";
import { HardCodedComponentConnector } from "../../connectors/HardCodedComponentConnector";
import { IconConnector } from "../../connectors/IconConnector";
import { LinkConnector } from "../../connectors/LinkConnector";
import { LinkListConnector } from "../../connectors/LinkListConnector";
import { MultiImageBlockConnector } from "../../connectors/MultiImageBlockConnector";
import { MediaConnector } from "../../connectors/MediaConnector";
import { ParagraphConnector } from "../../connectors/ParagraphConnector";
import { PromotionRuleConnector } from "../../connectors/PromotionRuleConnector";
import { SectionConnector } from "../../connectors/SectionConnector";
import { SplitContentContainer } from "../../connectors/SplitContentConnector";
import { TabsAndAccordionConnector } from "../../connectors/TabsAndAccordionConnector";
import { TimedContent } from "../TimedContent";
import { VideoPlayerConnector } from "../../connectors/VideoPlayerConnector";
import { ResponsiveBannerConnector } from "../ResponsiveBanner/ResponsiveBannerConnector";

// eslint-disable-next-line no-unused-vars
const FluidSections = ({ data, location, locale, useWrapper }) =>
	data?.map(section => {
		const id = section?.sys?.contentType?.sys?.id;
		return (
			<SectionConnector
				key={section.id}
				data={(id === "section" && section) || {}}
				locale={locale}
				useWrapper={
					useWrapper &&
					id !== "whiteSpaceComponent" &&
					id !== "componentBlock" &&
					id !== "responsiveBanner"
				}
			>
				{(() => {
					switch (id) {
						case "card":
							return <CardConnector data={section} key={section.id} />;
						case "collection":
							return <CollectionConnector data={section} locale={locale} key={section.id} />;
						case "componentBlock":
							return <ComponentBlock data={section} key={section.id} />;
						case "hardCodedComponent":
							return <HardCodedComponentConnector data={section} key={section.id} />;
						case "icon":
							return <IconConnector name={section.iconName} key={section.id} />;
						case "link":
							return <LinkConnector data={section} key={section.id} />;
						case "listLink":
							return <LinkListConnector data={section} key={section.id} />;
						case "multiImageBlock":
							return <MultiImageBlockConnector data={section} key={section.id} />;
						case "gallery":
							return <MediaConnector data={section} key={section.id} />;
						case "paragraph":
							return <ParagraphConnector data={section} key={section.id} />;
						case "promotionRule":
							return <PromotionRuleConnector data={section} key={section.id} locale={locale} />;
						case "section":
							return (
								<FluidSections data={section.components} key={section.id} useWrapper={false} />
							);
						case "splitContentWrapper":
							return <SplitContentContainer data={section} key={section.id} />;
						case "storeInfo":
							return <ContactConnector data={section} key={section.id} />;
						case "tabsAndAccordion":
							return <TabsAndAccordionConnector data={section} key={section.id} />;
						case "timedComponent":
							return <TimedContent data={section} key={section.id} />;
						case "videoPlayer":
							return <VideoPlayerConnector data={section} key={section.id} />;
						case "responsiveBanner":
							return <ResponsiveBannerConnector data={section} key={section.id} />;
						default:
							return <></>;
					}
				})()}
			</SectionConnector>
		);
	});

export default FluidSections;
export { FluidSections };

/* Declare page prop */
FluidSections.propTypes = {
	data: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			sys: PropTypes.shape({
				contentType: PropTypes.shape({
					sys: PropTypes.shape({
						id: PropTypes.string.isRequired,
					}).isRequired,
				}).isRequired,
			}).isRequired,
		}).isRequired
	).isRequired,
	location: PropTypes.object,
	locale: PropTypes.string,
	useWrapper: PropTypes.bool,
};

FluidSections.defaultProps = {
	useWrapper: true,
	location: null,
	locale: null,
};
