import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Heading as HeadingComponent } from "@sunwing/shared-components";
import * as styles from "./Heading.module.scss";

const HeadingAsElement = {
	h1: "h1",
	h2: "h2",
	h3: "h3",
	p: "p",
	span: "span",
	div: "div",
};

const HeadingSize = {
	h1: "h1",
	h2: "h2",
	h3: "h3",
	base: "base",
	small: "small",
};

const HeadingWeight = {
	normal: "normal",
	bold: "bold",
};

const HeadingAlign = {
	left: "left",
	center: "center",
	right: "right",
};

const headingStyles = {
	"heading--h1": styles.headingH1,
	"heading--h2": styles.headingH2,
	"heading--h3": styles.headingH3,
	"heading--base": styles.headingBase,
	"heading--small": styles.headingSmall,
	"heading--bold": styles.headingBold,
	"heading--normal": styles.headingNormal,
	"heading--left": styles.headingLeft,
	"heading--center": styles.headingCenter,
	"heading--right": styles.headingRight,
	"heading--uppercase": styles.headingUppercase,
};
const Heading = ({ id, as, className, size, weight, align, uppercase, children }) => (
	<HeadingComponent
		id={id}
		className={cx(className)}
		classNames={headingStyles}
		as={as}
		size={size || as}
		weight={weight}
		align={align}
		uppercase={uppercase}
	>
		{children}
	</HeadingComponent>
);

Heading.displayName = "Heading";

Heading.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	/**
	 * Import HeadingAsElement for list of `as` available
	 */
	as: PropTypes.oneOf(Object.keys(HeadingAsElement)),
	/**
	 * Import HeadingSize for list of `size` available
	 */
	size: PropTypes.oneOf(Object.keys(HeadingSize)),
	/**
	 * Import HeadingWeight for list of `weight` available
	 */
	weight: PropTypes.oneOf(Object.keys(HeadingWeight)),
	/**
	 * Import HeadingAlign for list of `align` available
	 */
	align: PropTypes.oneOf(Object.keys(HeadingAlign)),
	uppercase: PropTypes.bool,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

Heading.defaultProps = {
	id: undefined,
	className: undefined,
	as: HeadingAsElement.h1,
	size: undefined,
	weight: undefined,
	align: "left",
	uppercase: false,
	children: undefined,
};

export default Heading;
export { Heading, HeadingAsElement, HeadingSize, HeadingWeight, HeadingAlign, headingStyles };
