/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-props-no-spreading */
// import React, { useState, useReducer } from "react";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import * as dayjs from "dayjs";
import { Heading } from "../../Heading";
import { RCL as useTranslation } from "../../RCL";
import { Icon } from "../../Icons";
import { Button } from "../../Button";

// Field Components
import { DateField } from "./DateField";
import { GatewayField } from "./GatewayField";
import { DestinationField } from "./DestinationField";
import { DurationField } from "./DurationField";
import RoomGuestsField from "../Common/Components/RoomsAndGuests";

// Search Filter Components

import { SlideOutPanel } from "../../SlideOutPanel";
import { FilterForm } from "./FilterForm";

import * as parentStyles from "../SearchBox.module.scss";
import * as styles from "./Packages.module.scss";

const Packages = ({ language, config }) => {
	const dictionary = {
		"3-or-4-days": useTranslation({ searchKey: "3-or-4-days" }),
		"5-to-10-days": useTranslation({ searchKey: "5-to-10-days" }),
		"11-to-16-days": useTranslation({ searchKey: "11-to-16-days" }),
		"17-days-or-more": useTranslation({ searchKey: "17-days-or-more" }),

		"days-only": useTranslation({ searchKey: "days-only" }),
		"sb-packages-heading": useTranslation({ searchKey: "sb-packages-heading" }),
		"sb-packages-subheading": useTranslation({ searchKey: "sb-packages-subheading" }),

		"traveling-from": useTranslation({ searchKey: "travelling-from" }),
		"going-to-sov": useTranslation({ searchKey: "going-to-sov" }),
		when: useTranslation({ searchKey: "when" }),
		duration: useTranslation({ searchKey: "duration" }),
		search: useTranslation({ searchKey: "search" }),
		"group-deals-slug": useTranslation({ searchKey: "group-deals-slug" }),
		Filters: useTranslation({ searchKey: "filters" }),
		"filter-close": useTranslation({ searchKey: "modal-close" }),
		"going-to-field-is-required": useTranslation({ searchKey: "going-to-field-is-required" }),
	};

	// Filter states
	const [filters, setFilters] = useState({});

	// Gateway states
	const [gatewaySelected, setSelectedGateway] = useState(null);
	const [isGatewayChanged, setGatewayChanged] = useState(false);
	const [isValidGateway, setIsValidGateway] = useState(true);

	// Destination states
	const [destinationSelected, setSelectedDestination] = useState(null);
	const [isDestinationChanged, setDestinationChanged] = useState(false);
	const [isValidDestination, setIsValidDestination] = useState(true);

	// Date state
	const [departureDay, setDepartureDay] = useState(
		dayjs(config.calendar.startDate).startOf("day").add(config.calendar.defaultMin, "days")
	);
	// eslint-disable-next-line no-unused-vars
	const [isValidDepartureDate, setIsValidDepartureDate] = useState(true);

	/* /////////////////////////////////////////////////////////////
	// Duration drop-down logic
	///////////////////////////////////////////////////////////// */

	// Duration | Date state
	const onlyDays = [];
	const dayStart = 3;
	const dayLimit = 100;
	let durationOptions = [];

	const dateRange = [
		{
			value: "3",
			label: dictionary["3-or-4-days"],
			regex: "^[3-4]$",
			added: false,
			id: dictionary["3-or-4-days"],
		},
		{
			value: "7",
			label: dictionary["5-to-10-days"],
			regex: "^[5-9]|[1][0]$",
			added: false,
			id: dictionary["5-to-10-days"],
		},
		{
			value: "14",
			label: dictionary["11-to-16-days"],
			regex: "^[1][1-4]$",
			added: false,
			id: dictionary["11-to-16-days"],
		},
		{
			value: "17",
			label: dictionary["17-days-or-more"],
			regex: "^[1][7-9]|[2][0-8]$",
			added: false,
			id: dictionary["17-days-or-more"],
		},
	];

	for (let d = dayStart; d <= dayLimit; d++) {
		onlyDays.push({
			value: `${d}DAYS`,
			label: `${d} ${dictionary["days-only"]}`,
			regex: null,
			added: false,
			id: `${d} ${dictionary["days-only"]}`,
		});
	}

	durationOptions = durationOptions.concat(dateRange);
	durationOptions = durationOptions.concat(onlyDays);

	const [duration, setDuration] = useState(null);
	const [durationData, setDurationData] = useState([]);
	const [isValidDuration, setIsValidDuration] = useState(true);
	const [isIsSlidePanelOpen, setIsSlidePanelOpen] = useState(false);
	const [isFilterError, setIsFilterError] = useState(false);
	const filterErrorMessage = dictionary["going-to-field-is-required"];

	const updateDurationData = _destination => {
		const updatedDurationArray = [];

		durationOptions.forEach(durationOption => {
			const numericValue = parseInt(durationOption.value.replace("DAYS", ""), 10);
			const onlyDuration = durationOption.value.indexOf("DAYS") >= 0;

			_destination.durations.forEach(destDuration => {
				// Find a match to the duration availability and add it to the display array
				if (
					!durationOption.added &&
					((onlyDuration && destDuration === numericValue) ||
						(!onlyDuration &&
							new RegExp(`(${durationOption.regex})`).test(destDuration.toString())))
				) {
					// eslint-disable-next-line no-param-reassign
					durationOption.added = true;
					updatedDurationArray.push(durationOption);
				}
			});
		});

		// Update full display options
		setDurationData(updatedDurationArray);

		// Default to 7DAYS option if available
		let durationIndex =
			updatedDurationArray.findIndex(d => d?.value === "7DAYS") >= 0
				? updatedDurationArray.findIndex(d => d.value === "7DAYS")
				: 0;

		// Check for 5 to 10 option if available
		const durationIndex5to10 =
			updatedDurationArray.findIndex(d => d?.value === "7") >= 0
				? updatedDurationArray.findIndex(d => d.value === "7")
				: 0;

		// Set to 5 to 10 option as priority fallback
		if (durationIndex === 0 && durationIndex !== durationIndex5to10) {
			durationIndex = durationIndex5to10;
		}

		setDuration(updatedDurationArray[durationIndex]);
	};

	useEffect(() => {
		if (destinationSelected) {
			setIsFilterError(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [destinationSelected, setSelectedDestination]);

	/* /////////////////////////////////////////////////////////////
	// Rooms logic
	///////////////////////////////////////////////////////////// */

	const [selectedRoomsOccupancies, setSelectedRoomsOccupancies] = useState({
		rooms: 1,
		adults: 2,
		children: [],
		infants: 0,
	});

	// eslint-disable-next-line no-unused-vars
	const [isValidSelectedRoomsOccupancies, setIsValidSelectedRoomsOccupancies] = useState(true);

	/* /////////////////////////////////////////////////////////////
	// Filter logic
	///////////////////////////////////////////////////////////// */
	const filterDataTransfer = () => {
		let count = 0;
		let temp = "";
		if (
			filters &&
			Object.keys(filters).length > 0 &&
			Object.getPrototypeOf(filters) === Object.prototype
		) {
			// eslint-disable-next-line guard-for-in
			for (const key in filters) {
				if (filters[key] !== null) {
					switch (key) {
						case "no_hotel":
							temp += `&no_hotel=${filters[key].id}`;
							count += 1;
							break;
						case "star":
							temp += `&star=${filters[key].value}`;
							count += 1;
							break;

						case "hotelFeature":
							for (const feature in filters.hotelFeature) {
								if (feature !== null) {
									temp += `&${filters.hotelFeature[feature].value}=Y`;
									count += 1;
								}
							}
							break;
						case "direct_flight":
							if (filters[key].value === "Direct") {
								temp += "&direct_flight=Y";
								count += 1;
							}
							break;
						default:
							temp += `&price_maxl=${filters[key].value}`;
							count += 1;
					}
				}
			}
			return { filterCount: count, query: temp };
		}
		return { filterCount: count, query: temp };
	};

	/* /////////////////////////////////////////////////////////////
	// Form validation logic
	///////////////////////////////////////////////////////////// */

	const validateSearchCriteria = () => {
		let isValid = true;

		// Check if gateway has a value
		const validGateway = !!gatewaySelected;

		if (!validGateway) {
			isValid = false;
			setIsValidGateway(false);
		}

		const validDestination = !!destinationSelected;

		if (!validDestination) {
			isValid = false;
			setIsValidDestination(false);
		}

		const validDate = !!departureDay;

		if (!validDate) {
			isValid = false;
			setIsValidDepartureDate(false);
		}

		const validDuration = !!duration;

		if (!validDuration) {
			isValid = false;
			setIsValidDuration(false);
		}

		const validPax = selectedRoomsOccupancies != null || false;

		if (!validPax) {
			isValid = false;
			setIsValidSelectedRoomsOccupancies(false);
		}

		return isValid;
	};

	const handleFormSubmission = event => {
		event.preventDefault();

		if (!validateSearchCriteria()) return;

		// Determine children pax
		let childrenQuery = "";
		let childIndex = 0;
		selectedRoomsOccupancies.children.forEach((child, index) => {
			const childTemplate = `&non_adult_forf${index + 1}`;
			const childQueryString = `${childTemplate}=${child}`;
			childrenQuery += childQueryString;
			childIndex += 1;
		});

		// Process infant pax
		for (let i = childIndex; i < selectedRoomsOccupancies.infants + childIndex; i++) {
			const infantTemplate = `&non_adult_forf${i + 1}`;
			const infantQueryString = `${infantTemplate}=1`;
			childrenQuery += infantQueryString;
		}

		const isMobile = window.innerWidth <= 992;
		const baseURL = isMobile
			? "https://shopping.selloffvacations.com/cgi-bin/mobile/resultspackage-plus.cgi"
			: "https://shopping.selloffvacations.com/cgi-bin/resultspackage-plus.cgi";

		const bookingPathURL = `${baseURL}?language=${language}&alias=${config.alias}&code_ag=${
			config.code_ag
		}&gateway_dep=${gatewaySelected.value}&dest_dep=${
			destinationSelected.value
		}&date_dep=${departureDay.format("YYYY/MM/DD")}&duration=${duration.value}${
			filterDataTransfer().query
		}&nb_rooms=${selectedRoomsOccupancies.rooms}&nb_adult_forf=${
			selectedRoomsOccupancies.adults
		}&nb_child_forf=${
			selectedRoomsOccupancies.infants + selectedRoomsOccupancies.children.length
		}&nb_child=${
			selectedRoomsOccupancies.infants + selectedRoomsOccupancies.children.length
		}${childrenQuery}&isMobile=${isMobile}`;

		if (!window.dataLayer) {
			window.dataLayer = [];
		}

		// Search Widget Tracking
		window.dataLayer.push({
			event: "package_search",
			package_search_dep_date: departureDay.format("YYYY-MM-DD"), // YYYY-MM-DD
			package_search_dep_month: departureDay.format("MMMM"),
			package_search_duration: duration.value,
			package_search_from: gatewaySelected.label, // always proper name
			package_search_to: destinationSelected.label, // always proper name
			package_search_num_adult: selectedRoomsOccupancies.adults,
			package_search_num_children:
				selectedRoomsOccupancies.infants + selectedRoomsOccupancies.children.length,
			package_search_num_rooms: selectedRoomsOccupancies.rooms,
			package_search_total_pax:
				selectedRoomsOccupancies.adults +
				selectedRoomsOccupancies.infants +
				selectedRoomsOccupancies.children.length,
		});

		if (!isMobile) {
			// if popup is blocked - open in tab
			if (!window.open(bookingPathURL, "_blank")) {
				window.location.href = bookingPathURL;
			}
		} else {
			window.open(bookingPathURL, "_self");
		}
	};

	const baseProps = {
		id: "Search_Filter",
		labels: {
			title: dictionary.Filters,
			closeMenu: dictionary["filter-close"],
		},
		classNames: { titleContainer: styles.titleContainerSticky },
		handleClose: () => {
			setIsSlidePanelOpen(false);
		},
	};

	const SlidePanelHandler = () => {
		if (destinationSelected === null) {
			setIsSlidePanelOpen(false);
			setIsFilterError(true);
		} else {
			setIsSlidePanelOpen(!isIsSlidePanelOpen);
		}
	};

	const FilterSubmitHandler = data => {
		setIsSlidePanelOpen(false);
		setFilters(data);
	};

	return (
		<React.Fragment>
			<Heading as="div" size="h2">
				{dictionary["sb-packages-heading"]}
			</Heading>
			{dictionary["sb-packages-subheading"] && (
				<p className={parentStyles.subheading}>{dictionary["sb-packages-subheading"]}</p>
			)}
			<div id="sov-search-box">
				<form
					acceptCharset="UTF-8"
					noValidate
					className={styles.searchForm}
					onSubmit={handleFormSubmission}
				>
					<div className={styles.formRow}>
						<div className={styles.formColumn}>
							<div className={styles.inputContainer}>
								<GatewayField
									id="packages-gateways"
									label={dictionary["traveling-from"]}
									language={language}
									selected={gatewaySelected}
									error={!isValidGateway}
									resetError={() => setIsValidGateway(true)}
									onChange={setSelectedGateway}
									onUpdate={setGatewayChanged}
								/>
							</div>
						</div>
						<div className={styles.formColumn}>
							<div className={styles.inputContainer}>
								<DestinationField
									id="packages-destinations"
									label={dictionary["going-to-sov"]}
									language={language}
									selected={destinationSelected}
									error={!isValidDestination || isFilterError}
									resetError={() => setIsValidDestination(true)}
									onChange={setSelectedDestination}
									onUpdateData={updateDurationData}
									onUpdate={setDestinationChanged}
									gateway={gatewaySelected}
									isGatewayChanged={isGatewayChanged}
									setGatewayChanged={setGatewayChanged}
								/>
							</div>
						</div>
						<div className={styles.formColumn}>
							<div className={styles.inputContainer}>
								<DateField
									id="packages-date"
									label={dictionary.when}
									language={language}
									selected={departureDay}
									minDate={dayjs().startOf("day")}
									maxDate={
										config.calendar.maxDate
											? dayjs().startOf("day").add(config.calendar.maxDate, "days")
											: dayjs().startOf("day").add(365, "days")
									}
									error={!isValidDepartureDate}
									onChange={setDepartureDay}
								/>
							</div>
						</div>
						<div className={styles.formColumn}>
							<div className={styles.inputContainer}>
								<DurationField
									id="packages-duration"
									label={dictionary.duration}
									selectedDates={duration}
									data={durationData}
									error={!isValidDuration}
									resetError={() => setIsValidDuration(true)}
									onChange={setDuration}
								/>
							</div>
						</div>
						<div className={styles.formColumn}>
							<div className={styles.inputContainer}>
								<RoomGuestsField
									id="packages-rooms-guests"
									selectedRoomsOccupancies={selectedRoomsOccupancies}
									error={isValidSelectedRoomsOccupancies}
									onChange={setSelectedRoomsOccupancies}
									language={language}
									groupsURL={`/${language}/${dictionary["group-deals-slug"]}`}
									adultsMax={6}
									childrenMax={4}
									roomsMax={6}
									maxChildAge={17}
									searchType="Packages"
								/>
							</div>
						</div>
						<div>
							<Button
								type="submit"
								full
								size="md"
								theme="primary"
								defaultStyle={true}
								className={parentStyles.searchButton}
							>
								{dictionary.search}
							</Button>
						</div>
					</div>

					<div className={cx(styles.formRow, styles.filterRow)}>
						<div className={cx(styles.formColumn, styles.filterColumn)}>
							{isFilterError && <span className={styles.filterError}>{filterErrorMessage}</span>}
							{filters &&
								Object.keys(filters).length > 0 &&
								filterDataTransfer().filterCount !== 0 && (
									<span className={styles.statusIcon}>{filterDataTransfer().filterCount}</span>
								)}
							<Button
								type="button"
								size="md"
								theme="secondary"
								noStyle
								label="No style"
								onClick={SlidePanelHandler}
							>
								<span>
									<Icon name="filters" /> {dictionary.Filters}
								</span>
							</Button>
						</div>
					</div>
				</form>
			</div>

			<SlideOutPanel
				id="filter-slide-panel"
				{...baseProps}
				isOpen={isIsSlidePanelOpen}
				focusOnShouldIgnore={() => false}
			>
				<FilterForm
					id="filterForm"
					onSaveFilter={FilterSubmitHandler}
					language={language}
					destination={destinationSelected}
					isDestinationChanged={isDestinationChanged}
					setDestinationChanged={setDestinationChanged}
				/>
			</SlideOutPanel>
		</React.Fragment>
	);
};

Packages.propTypes = {
	language: PropTypes.oneOf(["en", "fr"]).isRequired,
	config: PropTypes.shape({
		code_ag: PropTypes.string,
		alias: PropTypes.string,
		calendar: PropTypes.shape({
			startDate: PropTypes.string,
			maxDate: PropTypes.number,
			defaultMin: PropTypes.number,
		}),
	}),
};

Packages.defaultProps = {
	config: {
		code_ag: "drv",
		alias: "drv",
		calendar: {
			startDate: undefined,
			maxDate: 365,
			defaultMin: 1,
		},
	},
};

export default Packages;
export { Packages };
