import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import * as dayjs from "dayjs";
import { RCL as useTranslation } from "../../RCL";
import { Heading } from "../../Heading";
import { validateRoomOccupancies } from "./utils/validate-room-occupancies";

// Field Components
import { ToField } from "./ToField";
import { DateField } from "./DateField";
import RoomGuestsField from "../Common/Components/RoomsAndGuests";
import { Button } from "../../Button";

import * as parentStyles from "../SearchBox.module.scss";
import * as styles from "./Hotels.module.scss";

const cx = classNames.bind(styles);

const Hotels = ({ language, config }) => {
	const dictionary = {
		"sb-hotels-heading": useTranslation({ searchKey: "sb-hotels-heading" }),
		"sb-hotels-subheading": useTranslation({ searchKey: "sb-hotels-subheading" }),
		destination: useTranslation({ searchKey: "destination" }),
		search: useTranslation({ searchKey: "search" }),
	};

	// To destination selection
	const [selectedTo, setSelectedTo] = useState(null);
	const [isValidSelectedTo, setIsValidSelectedTo] = useState(true);

	const isMinDate = typeof config?.calendar?.minDate === "number";
	const isMaxDate = typeof config?.calendar?.maxDate === "number";
	const isMinRange = typeof config?.calendar?.minRange === "number";
	const isMaxRange = typeof config?.calendar?.maxRange === "number";

	// Set up date range defaults
	const minRange = isMinDate && isMinRange ? config.calendar.minDate + config.calendar.minRange : 1;
	const maxRange = isMinDate && isMaxRange ? config.calendar.minDate + config.calendar.maxRange : 7;

	// Date range selection
	const [selectedDates, setSelectedDates] = useState([
		dayjs().startOf("day").add(minRange, "days"),
		dayjs().startOf("day").add(maxRange, "days"),
	]);
	const [isValidSelectedDates, setIsValidSelectedDates] = useState(true);

	/* /////////////////////////////////////////////////////////////
	// Rooms states
	///////////////////////////////////////////////////////////// */

	const [selectedRoomsOccupancies, setSelectedRoomsOccupancies] = useState({
		rooms: 1,
		adults: 2,
		children: [],
		infants: 0,
	});

	const [isValidSelectedRoomsOccupancies, setIsValidSelectedRoomsOccupancies] = useState(true);

	/* /////////////////////////////////////////////////////////////
	// Form validation logic
	///////////////////////////////////////////////////////////// */

	const validateSearchCriteria = () => {
		const validSelectedTo = !!selectedTo;
		setIsValidSelectedTo(validSelectedTo);

		const validSelectedDates = selectedDates.every(date => date);
		setIsValidSelectedDates(validSelectedDates);

		const validSelectedRoomsOccupancies = validateRoomOccupancies(selectedRoomsOccupancies);
		setIsValidSelectedRoomsOccupancies(validSelectedRoomsOccupancies);

		return validSelectedTo && validSelectedDates && validSelectedRoomsOccupancies;
	};

	const handleFormSubmission = event => {
		event.preventDefault();

		// Validation
		if (!validateSearchCriteria()) return;

		const params = {
			agency: "4162591118",
			cheapest_fares: "N",
			province: "",
			language,
			alias: config.alias,
			code_ag: config.code_ag,
			dest_dep: selectedTo.value,
			engines_hotel: "S",
			searchtype: "HO",
			group_id: "",
			dest_dep_text: selectedTo.label,
			date_dep: selectedDates[0].format("YYYYMMDD"),
			date_ret: selectedDates[1].format("YYYYMMDD"),
			nb_adult: selectedRoomsOccupancies.adults,
			nb_rooms: selectedRoomsOccupancies.rooms,
			nb_child: selectedRoomsOccupancies.children.length + selectedRoomsOccupancies.infants,
		};

		if (selectedRoomsOccupancies.children.length) {
			for (let index = 0; index < selectedRoomsOccupancies.children.length; index++) {
				const childAge = selectedRoomsOccupancies.children[index];

				params[`non_adult${index + 1}`] = childAge;
			}
		}

		if (selectedRoomsOccupancies.infants) {
			for (let index = 0; index < selectedRoomsOccupancies.infants; index++) {
				params[`non_adult${selectedRoomsOccupancies.children.length + index + 1}`] = 1;
			}
		}

		const isMobile = window.innerWidth <= 992;
		const baseURL = isMobile
			? "https://shopping.selloffvacations.com/cgi-bin/mobile/results-hotel.cgi"
			: "https://shopping.selloffvacations.com/cgi-bin/results-hotel.cgi";

		const bookingPathURL = `${baseURL}?${Object.entries(params)
			.filter(pair => pair[1] !== null)
			.map(pair => pair.map(encodeURIComponent).join("="))
			.join("&")}`;

		if (!isMobile) {
			// if popup is blocked - open in tab
			if (!window.open(bookingPathURL, "_blank")) {
				window.location.href = bookingPathURL;
			}
		} else {
			window.open(bookingPathURL, "_self");
		}
	};

	return (
		<React.Fragment>
			<Heading as="div" size="h2">
				{dictionary["sb-hotels-heading"]}
			</Heading>
			{dictionary["sb-hotels-subheading"] && (
				<p className={cx(parentStyles.subheading)}>{dictionary["sb-hotels-subheading"]}</p>
			)}
			<div id="sov-search-box">
				<form
					acceptCharset="UTF-8"
					noValidate
					className={cx("searchForm")}
					onSubmit={handleFormSubmission}
				>
					<div className={cx("formRow")}>
						<div className={cx("formColumn")}>
							<div className={cx("inputContainer")}>
								<ToField
									id="hotel-destination"
									label={dictionary.destination}
									language={language}
									selectedTo={selectedTo}
									error={!isValidSelectedTo}
									resetError={() => setIsValidSelectedTo(true)}
									onChange={setSelectedTo}
								/>
							</div>
						</div>
						<div className={cx("formColumn")}>
							<div className={cx("inputContainer")}>
								<DateField
									id="hotel-date-range"
									language={language}
									selectedDates={selectedDates}
									error={!isValidSelectedDates}
									resetError={() => setIsValidSelectedDates(true)}
									onChange={setSelectedDates}
									minDate={
										isMinDate && config?.calendar?.minDate >= 0
											? dayjs().startOf("day").add(config.calendar.minDate, "days")
											: undefined
									}
									maxDate={
										isMaxDate && config?.calendar?.maxDate >= 0
											? dayjs().startOf("day").add(config.calendar.maxDate, "days")
											: undefined
									}
								/>
							</div>
						</div>
						<div className={cx("formColumn")}>
							<div className={cx("inputContainer", "inputContainerPopOver")}>
								<RoomGuestsField
									id="hotel-rooms-guests"
									classNames={{
										popover: styles.popover,
									}}
									selectedRoomsOccupancies={selectedRoomsOccupancies}
									error={isValidSelectedRoomsOccupancies}
									onChange={setSelectedRoomsOccupancies}
									language={language}
									roomsMax={6}
									adultsMax={6}
									childrenMax={5}
									occupancyMax={11}
									searchType="Hotels"
								/>
							</div>
						</div>
						<div className={cx("formColumnHalf")}>
							<Button
								type="submit"
								full
								size="md"
								theme="primary"
								defaultStyle={true}
								className={parentStyles.searchButton}
							>
								{dictionary.search}
							</Button>
						</div>
					</div>
				</form>
			</div>
		</React.Fragment>
	);
};

Hotels.propTypes = {
	language: PropTypes.oneOf(["en", "fr"]).isRequired,
	config: PropTypes.shape({
		code_ag: PropTypes.string,
		alias: PropTypes.string,
		calendar: PropTypes.shape({
			minRange: PropTypes.number,
			maxRange: PropTypes.number,
			minDate: PropTypes.number,
			maxDate: PropTypes.number,
		}),
	}),
};

Hotels.defaultProps = {
	config: {
		code_ag: "drv",
		alias: "drv",
		calendar: {
			minRange: 1,
			maxRange: 6,
			minDate: 0,
			maxDate: 365,
		},
	},
};

export default Hotels;
export { Hotels };
